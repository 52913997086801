import React from "react";

const EXPERIENCES = [
	{
		role: "Software Engineer",
		site: (
			<a
				rel="noopener noreferrer"
				target="_blank"
				href="https://palolo.com/://www.bdpinternational.com/"
			>
				palolo
			</a>
		),
		content:
			"I'm developing our daily payments feature across the stack.  I do the delicate work of managing our our user's funds with a strict testing regiment and even stricter type security.",
		technologies: "TypeScript, React, Prisma",
		dates: "2023 — Current",
	},
	{
		role: "lead developer",
		site: (
			<a
				rel="noopener noreferrer"
				target="_blank"
				href="https://www.aboltc.com"
			>
				aboltc
			</a>
		),
		content:
			"I developed smart contracts and websites for nft projects, netting close to $500K in total sales in the first half of 2022.  i serve as an advisor to junior engineers and make tech stack decisions for our organization's community managers.",
		technologies: "React, TypeScript, GraphQL, Solidity",
		dates: "2021 — 2022",
	},
	{
		role: "front-end software engineer",
		site: (
			<a
				rel="noopener noreferrer"
				target="_blank"
				href="https://www.1build.com"
			>
				1build
			</a>
		),
		content:
			"I built out interfaces for 1build's sites and flagship application using using React, TypeScript, GraphQL and styled-components. I brought features from design into production such as our estimator rating system, file management, and takeoff data management system.",
		technologies: "React, TypeScript, GraphQL, Go",
		dates: "2021 — 2022",
	},
	{
		role: "front-end software engineer",
		site: (
			<a
				rel="noopener noreferrer"
				target="_blank"
				href="https://www.barnesfoundation.org"
			>
				barnes foundation
			</a>
		),
		content: (
			<>
				I developed the React front-ends to the foundation's flagship
				sites, live-streaming platform, and membership experience
				application. Our{" "}
				<a
					rel="noopener noreferrer"
					target="_blank"
					href="https://aws.amazon.com/blogs/publicsector/barnes-foundation-takes-art-education-virtual-and-reaches-record-number-of-new-learners/"
				>
					award-winning live-streaming platform
				</a>{" "}
				has been adopted organization-wide and serves as a new revenue
				mechanism for classes and guided tours.
			</>
		),
		technologies: "TypeScript, React, Redux, Kotlin, AWS",
		dates: "2020 — 2021",
	},
	{
		role: "junior developer",
		site: (
			<a
				rel="noopener noreferrer"
				target="_blank"
				href="https://www.comcast.com"
			>
				comcast
			</a>
		),
		content:
			"I worked across the stack with my team on a React, node.js, and postgres stack application that used OCR and Comcast’s service-oriented architecture to replicate user input. This application tested and recorded run-time errors across hundreds of devices in our War Games lab.",
		technologies: "JavaScript, TypeScript, React, node.js, SQL",
		dates: "2019 — 2020",
	},
];

export const Experience = ({}) => {
	return (
		<>
			{EXPERIENCES.map((experience) => (
				<div
					css={`
						margin-bottom: 16px;
					`}
				>
					<span
						css={`
							display: flex;
						`}
					>
						<b>{experience.role}</b>&nbsp;(<i>{experience.site}</i>){" "}
						<span
							css={`
								margin-left: auto;
							`}
						>
							{experience.dates}
						</span>
					</span>
					<div>{experience.content}</div>
					<div>({experience.technologies})</div>
				</div>
			))}
		</>
	);
};
