import React from "react";

const EDUCATION = [
	{
		school: "Cornell University",
		degree: "UX Design, Certificate",
		dates: "2020",
	},
	{
		school: "Community College of Philadelphia",
		degree: "Computer Science, AS",
		dates: "2018 — 2019",
	},
	{
		school: "Boston University",
		degree: "Sociology, BA",
		dates: "2010 — 2014",
	},
];

export const Education = ({}) => {
	return (
		<div
			css={`
				margin-bottom: 16px;
			`}
		>
			{EDUCATION.map((experience) => (
				<div>
					<span
						css={`
							display: flex;
						`}
					>
						<b>{experience.school}</b>&nbsp;(
						<i>{experience.degree}</i>){" "}
						<span
							css={`
								margin-left: auto;
							`}
						>
							{experience.dates}
						</span>
					</span>
				</div>
			))}
		</div>
	);
};
