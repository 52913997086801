import { About } from "../components/About";
import { AppWrapper } from "../components/AppWrapper";
import { Education } from "../components/Education";
import { Experience } from "../components/Experience";
import { Project } from "../components/Project";
import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

const query = graphql`
	fragment sanityProject on SanityProject {
		title
		_id
		slug {
			current
		}
		technologies {
			technology
		}
		_rawBody
		body {
			_rawChildren
		}
	}

	query AllProjects2 {
		allSanityProject {
			edges {
				node {
					...sanityProject
				}
			}
		}
	}
`;

// markup
const IndexPage = () => {
	const { allSanityProject } = useStaticQuery(query);

	return (
		<AppWrapper isResume>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Resume — Andy Gallagher</title>
			</Helmet>
			<main>
				<About isResume />
				<hr />
				<h3
					css={`
						margin: 2rem 0 1rem;
					`}
				>
					<i>experience</i>
				</h3>
				<Experience />
				<hr />
				<h3
					css={`
						margin: 2rem 0 1rem;
					`}
				>
					<i>education</i>
				</h3>
				<Education />
				<hr />
				<h3
					css={`
						margin: 2rem 0 1rem;
					`}
				>
					<i>selected work</i>
				</h3>

				{allSanityProject.edges
					.filter((project) =>
						["-888", "-777", "-1", "1", "3"].includes(
							project.node.slug.current
						)
					)
					.sort(
						(projectA, projectB) =>
							parseInt(projectA.node.slug.current) -
							parseInt(projectB.node.slug.current)
					)
					.map(({ node }) => {
						return <Project key={node._id} project={node} />;
					})}
			</main>
		</AppWrapper>
	);
};

export default IndexPage;
